import { Component, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { CommonModule, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { environment } from './../environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit{
  title = 'pageself-site';
  environment = environment;
  currentUrl: string = '';
  platformId: Object = inject(PLATFORM_ID);
  isServer: boolean = isPlatformServer(this.platformId);
  isBrowser: boolean = isPlatformBrowser(this.platformId);
  
  ngOnInit(): void {
    if (this.isServer) {
      console.log("Hello from the server!");
    } else {
      console.log("Hello from the browser!");
      this.currentUrl = window.location.href;
    }      
  }
}
